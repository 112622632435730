<template >
  <div class="containter">
    <div class="headArea">
      <img class="headPic" :src="headPic" />
      <div class="headLine">
        <div class="leftUserArea">
          <div class="userImgArea">
            <!-- <img :src="user.avatar" @click="gotoNext('mine')" style="width:100%;height:100%;" /> -->
            <van-image
              fit="cover"
              :src="user.avatar"
              @click="gotoNext('mine')"
              style="width:100%;height:100%;"
            >
              <template v-slot:error>
                <img src="../../assets/img/avatar.png" style="width:100%;height:100%;" alt />
              </template>
            </van-image>
          </div>
          <div class="jifenArea">积分：{{jifen}}</div>
        </div>
        <div class="rightDepart">
          <img :src="departIcon" class="departIcon" />
          <div class="marginRight10" style="margin-left: 0.1rem">{{user.deptName}}</div>
        </div>
      </div>
      <div class="threeTapLine">
        <div class="tabOneItem marginLeft" @click="qcCode">
          <img :src="qrcode" class="tabOneItemImg" />
          <span>扫码签到</span>
        </div>
        <div class="tabOneItem" @click="gotoNext('taskList')">
          <img :src="renwu" class="tabOneItemImg" />
          <span>任务</span>
        </div>
        <div class="tabOneItem marginRight" @click="gotoNext('activityList')">
          <img :src="activity" class="tabOneItemImg" />
          <span>活动</span>
        </div>
      </div>
    </div>
    <div class="bannerArea">
      <div class="bannerList">
        <van-swipe class="my-swipe" :autoplay="4000" indicator-color="rgb(252,0,36)">
          <van-swipe-item
            v-for="item in bannersList"
            :key="item.id"
            @click="openBannerPage(item.linkUrl)"
          >
            <!-- <img
              :src="item.imgUrl"
              style="width:100%;height:100%;"
              @click="openBannerPage(item.linkUrl)"
            />-->
            <van-image fit="cover" :src="item.imgUrl" style="width:100%;height:100%;" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="linkTabListArea">
      <div class="linkTabList">
        <div class="linkTabListOne">
          <div
            v-for="item in tabLinkListOne"
            :key="item.title"
            @click="gotoNext(item.link)"
            class="linkTabItem"
          >
            <img :src="item.img" class="linkImg" />
            <div class="marinTop">{{item.title}}</div>
          </div>
        </div>
        <div class="linkTabListOne">
          <div
            v-for="item in tabLinkListTwo"
            :key="item.title"
            @click="gotoNext(item.link)"
            class="linkTabItem"
          >
            <img :src="item.img" class="linkImg" />
            <div class="marinTop">{{item.title}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="fengcaiTitleLine">
      <div class="fengcaiTitleTitle">党建风采</div>
      <div class="fengcaiTitleMore" @click="moreFengcai">更多</div>
    </div>
    <div class="dangjianfengcaiArea">
      <div class="fengcaiSwipe">
        <van-swipe
          class="my-swipe-fengcai"
          :autoplay="3000"
          touchable
          indicator-color="rgb(252,0,36)"
        >
          <van-swipe-item v-for="item in djfengcaiList" :key="item.id">
            <div class="fengcaiTab">
              <div class="fengcaiTabItem">
                <img :src="dangjian" class="dangJianImg" />
                <div class="fengcaiTabItemImgArea" @click="fengcaiDetail(item.id)">
                  <!-- <img :src="item.titleImg" style="width:100%;height:100%" /> -->
                  <van-image fit="cover" :src="item.titleImg" style="width:100%;height:100%" />
                </div>
                <div class="fengcaiTabItemContentArea">
                  <div class="fengcaiTabItemTitleLine">{{item.title}}</div>
                  <div
                    class="fengcaiTabItemContentLine"
                  >{{item.description&&item.description.length>40?item.description.slice(0,36)+'...':item.description}}</div>
                  <div
                    class="fengcaiTabItemContentLocationLine"
                    style="background:rgba(0,0,0,0);color:#999999;"
                  >
                    <!-- <img src="./imgs/locationRed.png" class="locationRed" /> -->
                    <span>{{item.publishTime}}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="fengcaiTitleLine margin20">
      <div class="fengcaiTitleTitle">最新活动/志愿服务</div>
      <div class="fengcaiTitleMore" @click="gotoNext('activityList')">更多</div>
    </div>
    <div
      v-for="item in activityList"
      :key="item.id"
      @click="moreActivityDetail(item.id)"
      class="activityItem"
    >
      <div class="activityItemImgArea">
        <!-- <img :src="item.websiteBanner" style="width:100%;height:100%;" /> -->
        <van-image fit="cover" :src="item.websiteBanner" style="width:100%;height:100%;" />
      </div>
      <div class="activityItemTitle">{{item.activityName}}</div>
      <div class="activityItemContent">{{item.description}}</div>
      <div class="activityTabBottom">
        <div class="activityTabBottomLine">
          <img src="./imgs/locationRed.png" class="locationRed inlineBlock" />
          <span class="locationFont inlineBlock">{{item.deptName}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import {
  getDangjianElegant,
  getBannersList,
  getUserInfo,
  getScoreintegralinfos
} from '../../api/mainPage';
import { signInActivity } from '../../api/activity';
import { getActivityList } from '../../api/activity';
import * as dd from 'dingtalk-jsapi';
// 引入ios快速点击
import FastClick from 'fastclick';

export default {
  name: 'MainPage',

  data() {
    return {
      headPic: require('./imgs/headBack.png'),
      departIcon: require('./imgs/departIcon.png'),
      qrcode: require('./imgs/QrCode.png'),
      renwu: require('./imgs/renwu.png'),
      activity: require('./imgs/activity.png'),
      dangjian: require('./imgs/-dangjian@2x.png'),
      errorPicAvatar: require('../../assets/img/list-compre.png'),
      user: { avtar: '', count: 38 },
      jifen: 0,
      departLocation: {
        name: '宝华洲社区小西门支部'
      },
      bannersList: [],
      tabLinkListOne: [
        {
          title: '微心愿',
          img: require('./imgs/smallWish.png'),
          link: 'tinyWish'
        },
        {
          title: '心里话',
          img: require('./imgs/heartWord.png'),
          link: 'heartWord'
        },
        {
          title: '党建地图',
          img: require('./imgs/dangMap.png'),
          link: 'gridMap'
        },
        {
          title: '结对帮扶',
          img: require('./imgs/jieduiBang.png'),
          link: 'pairToHelp'
        },
        {
          title: '生活咨询',
          img: require('./imgs/lifeConsole.png'),
          link: 'lifeConsultationList'
        }
      ],
      tabLinkListTwo: [
        {
          title: '综合资讯',
          img: require('./imgs/informationNew.png'),
          link: 'compreInfo'
        },
        {
          title: '情况反馈',
          img: require('./imgs/eventFeedback.png'),
          link: 'sfDashBoardList'
        },
        {
          title: '时空印相',
          img: require('./imgs/sgjn.png'),
          link:
            'https://ds.alipay.com/?scheme=alipays://platformapi/startapp?appId=2021001101638409'
        },
        {
          title: '西湖先锋',
          img: require('./imgs/xihuxianfeng.png'),
          link: ''
        },
        {
          title: '学习强国',
          img: require('./imgs/studyStrong.png'),
          link: ''
        }
      ],
      djfengcaiList: [],
      activityList: []
    };
  },
  watch: {},
  mounted() {
    FastClick.attach(document.body);

    var startX;

    document.addEventListener('touchstart', function(e) {
      startX = e.targetTouches[0].pageX;
    });

    document.addEventListener('touchmove', function(e) {
      var moveX = e.targetTouches[0].pageX;

      if (Math.abs(moveX - startX) > 0) {
        e.preventDefault();
      }
    });
  },
  created() {
    dd.biz.navigation.setTitle({
      title: '智慧党建', //控制标题文本，空字符串表示显示默认文本
      onSuccess: function(result) {
        /*结构
        {
        }*/
      },
      onFail: function(err) {}
    });
    // 设置左侧返回按钮直接关闭程序IOS
    // dd.biz.navigation.setLeft({
    //   control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
    //   text: '', //控制显示文本，空字符串表示显示默认文本
    //   onSuccess: function(result) {
    //     //如果control为true，则onSuccess将在发生按钮点击事件被回调
    //     dd.biz.navigation.close({
    //       onSuccess: function(result) {
    //       },
    //       onFail: function(err) {}
    //     });
    //   },
    //   onFail: function(err) {}
    // });
    // 获取个人信息
    this.getPersonCenter();
    // 获取积分
    this.getJifen();
    // 获取轮播图
    this.getBannerList();
    // 获取党建风采
    this.getDangjianElegant();
    // 获取活动的列表
    this.getActivityList();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    gotoList() {
      this.$router.push({ name: 'messageList' });
    },
    getJifen() {
      const this_ = this;
      getScoreintegralinfos({})
        .then(res => {
          if (res.success) {
            this_.jifen = res.data.integral;
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    getPersonCenter() {
      const this_ = this;
      getUserInfo({})
        .then(res => {
          if (res.success) {
            this_.user = res.data;
            localStorage.setItem('user', JSON.stringify(res.data));
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getBannerList() {
      getBannersList({})
        .then(res => {
          if (res.success) {
            this.bannersList = res.data;
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 打开banner图的链接页
    openBannerPage(link) {
      if (link) {
        dd.biz.util.openLink({
          url: link, //要打开链接的地址
          onSuccess: function(result) {
            /**/
          },
          onFail: function(err) {}
        });
      }
    },
    getDangjianElegant() {
      getDangjianElegant({})
        .then(res => {
          if (res.success) {
            const djfengcaiList = res.data;

            this.djfengcaiList =
              djfengcaiList && djfengcaiList.length > 5
                ? djfengcaiList.slice(0, 5)
                : djfengcaiList;
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getActivityList() {
      getActivityList({
        current: 1,
        size: 5,
        status: 0
      })
        .then(res => {
          if (res.success) {
            this.activityList = res.data.records;
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 活动详情
    moreActivityDetail(id) {
      this.$router.push({
        name: 'activityDetail',
        query: { id: id, selectRow: 0 }
      });
    },
    // 扫码签到
    qcCode() {
      dd.biz.util.scan({
        type: 'all', // type 为 all、qrCode、barCode，默认是all。
        onSuccess: function(data) {
          data = data.text;
          data = JSON.parse(data);
          signInActivity({
            id: data.id,
            type: data.type
          })
            .then(res => {
              if (res.success) {
                Notify({ type: 'success', message: '签到成功' });
              } else {
                Notify({ type: 'warning', message: res.msg });
              }
            })
            .catch(err => {
              Notify({ type: 'warning', message: err.response.data.msg });
              console.log(err);
            });
        },
        onFail: function(err) {
          console.log(err);
        }
      });
    },
    // 更多党建风采
    moreFengcai() {
      this.$router.push({ name: 'compreInfo', query: { typeKey: 1 } });
    },
    // 风采详情
    fengcaiDetail(id) {
      this.$router.push({ name: 'compreInfoDetail', query: { id: id } });
    },
    gotoNext(name) {
      if (name) {
        if (name.indexOf('http') > -1) {
          dd.biz.util.openLink({
            url: name, //要打开链接的地址
            onSuccess: function(result) {
              /**/
            },
            onFail: function(err) {}
          });
        } else {
          if(name=='gridMap'){
            if(localStorage.getItem('tenantId').substring(0,9)=='330702101'){
              this.$router.push({ name: name });
            }
          }else{
            this.$router.push({ name: name });
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;

/deep/ .van-swipe__indicator {
  background-color: #999999;
  border: #222222 0.6 * $px solid;
  z-index: 100;
}
html body {
  width: 100%;
  height: 100%;
  // touch-action: none !important;
}
ƒ .containter {
  height: 100%;
  width: 100%;
  position: relative;
}
.headArea {
  width: 100%;
  height: 162 * $px;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.headPic {
  position: absolute;
  width: 100%;
  top: 0rem;
  left: 0rem;
}
.headLine {
  width: 100%;
  // height: 50 * $px;
  display: flex;
  justify-content: space-between;
}
.leftUserArea {
  width: 200 * $px;
  height: 25 * $px;
  display: flex;
  align-items: center;
  z-index: 1;
}
.userImgArea {
  width: 24 * $px;
  height: 24 * $px;
  border-radius: 12 * $px;
  margin-left: 12 * $px;
  background-color: grey;
  display: flex;
  overflow: hidden;
}
.jifenArea {
  font-size: 14 * $px;
  margin-left: 7 * $px;
  color: #ffffff;
}
.rightDepart {
  // height: 22 * $px;
  height: auto;
  padding-top: 5 * $px;
  padding-bottom: 5 * $px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
  border-radius: 11 * $px 0 0 11 * $px;
  display: flex;
  align-items: center;
  font-size: 11 * $px;
  color: #ffffff;
}
.departIcon {
  width: 9 * $px;
  height: 10 * $px;
  margin-left: 10 * $px;
}
.threeTapLine {
  position: absolute;
  top: 48 * $px;
  left: 0 * $px;
  width: 100%;
  height: 60 * $px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-top: 28 * $px;
}
.tabOneItem {
  width: 70 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 14 * $px;
  color: #ffffff;
  z-index: 1;
}
.tabOneItemImg {
  width: 36 * $px;
  height: 36 * $px;
}
.marginLeft {
  margin-left: 33 * $px;
}
.marginRight {
  margin-right: 33 * $px;
}
.bannerArea {
  position: absolute;
  width: 100%;
  height: 140 * $px;
  top: 120 * $px;
  left: 0 * $px;
}
.bannerList {
  width: 355 * $px;
  height: 100%;
  margin: 0 auto;
  border-radius: 10 * $px;
  overflow: hidden;
}
.my-swipe {
  color: #fff;
  font-size: 20 * $px;
  line-height: 140 * $px;
  text-align: center;
  height: 100%;
  background-color: rgb(244, 215, 219);
}
.linkTabListArea {
  width: 100%;
  height: 176 * $px;
  margin-top: 120 * $px;
}
.linkTabList {
  width: 355 * $px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.linkTabListOne {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
}
.linkTabItem {
  width: 48 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12 * $px;
  color: #222222;
}
.linkImg {
  width: 44 * $px;
  height: 44 * $px;
}
.marinTop {
  width: 100%;
  text-align: center;
  margin-top: 4 * $px;
  white-space: nowrap;
  overflow: hidden;
}
.fengcaiTitleLine {
  height: 42 * $px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fengcaiTitleTitle {
  margin-left: 10 * $px;
  color: #222222;
  font-size: 18 * $px;
}
.fengcaiTitleMore {
  color: #999999;
  font-size: 12 * $px;
  margin-right: 10 * $px;
}
.dangjianfengcaiArea {
  width: 100%;
  height: 130 * $px;
}
.my-swipe-fengcai {
  color: #fff;
  font-size: 20 * $px;
}
.fengcaiSwipe {
  width: 355 * $px;
  height: 100%;
  margin: 0 auto;
}
.fengcaiTab {
  width: 355 * $px;
  height: 130 * $px;
  display: flex;
  justify-content: center;
}
.fengcaiTabItem {
  margin-top: 3px;
  width: 349 * $px;
  height: 100 * $px;
  border-radius: 4 * $px;
  box-shadow: 0px 0px 6px 0px rgb(197, 200, 206);
  position: relative;
}
.dangJianImg {
  width: 59 * $px;
  height: 47 * $px;
  position: absolute;
  top: 0px;
  right: 8 * $px;
}
.fengcaiTabItemImgArea {
  width: 80 * $px;
  height: 80 * $px;
  margin: 10 * $px;
  float: left;
}
.fengcaiTabItemContentArea {
  height: 90 * $px;
  width: 240 * $px;
  margin-top: 10 * $px;
  float: left;
}
.fengcaiTabItemTitleLine {
  height: 16 * $px;
  line-height: 16 * $px;
  width: 100%;
  font-size: 14 * $px;
  color: rgb(75, 75, 75);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fengcaiTabItemContentLine {
  margin-top: 8 * $px;
  width: 100%;
  height: 35 * $px;
  font-size: 12 * $px;
  color: rgb(162, 162, 162);
  text-overflow: ellipsis;
  overflow: hidden;
}
.margin20 {
  margin-top: 20 * $px;
}
.activityItem {
  width: 355 * $px;
  height: 207.5 * $px;
  border-radius: 4 * $px;
  margin: 12 * $px auto;
  box-shadow: 0px 0px 6px 0px rgb(197, 200, 206);
  overflow: hidden;
}
.activityItemImgArea {
  width: 100%;
  height: 120 * $px;
  background-color: grey;
}
.activityItemTitle {
  margin-top: 10 * $px;
  margin-bottom: 9 * $px;
  margin-left: 10 * $px;
  width: 335 * $px;
  font-size: 14 * $px;
  color: #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.activityItemContent {
  margin-left: 10 * $px;
  width: 335 * $px;
  font-size: 12 * $px;
  height: 15 * $px;
  line-height: 15 * $px;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fengcaiTabItemContentLocationLine {
  height: 20 * $px;
  line-height: 20 * $px;
  font-size: 11 * $px;
  color: #ff192f;
  border-radius: 10 * $px;
  background-color: #f5f5f5;
  white-space: nowrap;
  display: inline-flex;
}
.locationRed {
  width: 9 * $px;
  height: 11 * $px;
  margin-left: 8 * $px;
  margin-top: 4 * $px;
  float: left;
}
.locationFont {
  margin-left: 4 * $px;
  margin-right: 8 * $px;
  float: left;
}
.activityItemContentLocationLine {
  //   height: 20 * $px;
  line-height: 20 * $px;
  font-size: 11 * $px;
  color: #ff192f;
  border-radius: 10 * $px;
  background-color: #f5f5f5;
  white-space: nowrap;
  display: inline-flex;
}
.activityTabBottomLine {
  height: 20 * $px;
  line-height: 20 * $px;
  font-size: 11 * $px;
  color: #ff192f;
  border-radius: 10 * $px;
  background-color: #f5f5f5;
  white-space: nowrap;
}
.activityTabBottom {
  height: 20 * $px;
  width: 300 * $px;
  margin-top: 8 * $px;
  margin-left: 10 * $px;
  display: flex;
}
.inlineBlock {
  display: inline-block;
}
.activityItemContentArea {
  height: 80 * $px;
  width: 100%;
}
.marginRight10 {
  margin-right: 10 * $px;
}
</style>



